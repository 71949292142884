<template>
  <div
    class="r-map-balloon"
    :key="count"
  >
    <img
      class="r-map-balloon__img"
      :src="img"
      alt=""
      loading="lazy"
    >
    <div
      class="r-map-balloon__info"
      :key="count"
    >
      <div class="flex">
        <p class="roquefort mt-1">
          {{ title }}
        </p>
        <div class="ml-2">
          <slot />
          <div
            :link-id="id"
            class="link-balloon"
          />
        </div>
      </div>

      <div class="mt-1 сaprino">
        {{ address }}
      </div>

      <!-- <div class="flex mt-3">
        <rir-icon class="mr-2" icon="geopoint" />
        <span class="briscola text-rocky">Построить маршрут</span>
      </div> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'BaloonCard',
  props: {
    title: {
      type: String
    },
    address: {
      type: String
    },
    img: {
      type: String
    },
    id: {
      type: String
    },
    favorite: {
      type: Boolean
    }
  },
  data() {
    return {
      count: 0,
      favoriteIds: []
    };
  },
  computed: {
    countInfo() {
      this.favoriteIds[this.id] = this.favorite;
      return this.favoriteIds[this.id];
    }
  },
  mounted() {
    this.favoriteIds[this.id] = this.favorite;
    this.reloadFavorite();
  },
  updated() {
    this.favoriteIds[this.id] = this.favorite;
    this.reloadFavorite();
  },
  methods: {
    reloadFavorite() {
      const link = document.querySelector(
        `.link-balloon[link-id="${this.id}"]`
      );

      if (this.favoriteIds[this.id]) {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3.01448C2 1.34963 3.34315 0 5
                            0H11C12.6569 0 14 1.34963 14 3.01448V13.9866C14 15.6717
                            12.0601 16.6085 10.7506 15.5558L8 13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      } else {
        link.innerHTML = `<svg width="16" class="ml-auto pointer" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 2.00966C4.44772 2.00966 4 2.45953
                            4 3.01448V13.9866L6.75061 11.7755C7.48105 11.1883
                            8.51895 11.1883 9.24939 11.7755L12 13.9866V3.01448C12 2.45953 11.5523 2.00966 11
                            2.00966H5ZM2 3.01448C2 1.34963 3.34315 0 5 0H11C12.6569
                            0 14 1.34963 14 3.01448V13.9866C14 15.6717 12.0601 16.6085 10.7506 15.5558L8
                            13.3447L5.24939 15.5558C3.93986 16.6085 2 15.6717 2 13.9866V3.01448Z" fill="#3D75E4"/>
                          </svg>`;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  @media(max-width: 491px){
    width: 100%;
    height: 200px;
  }
  &__img {
    width: 100%;
    height: 122px;
  }

  &__info {
    font-family: 'Golos UI';
    padding: 16px;
  }
}

.pointer {
  cursor: pointer;
}

.padding_b {
  padding-bottom: 0px;
}

.padding_t {
  padding-top: 0px;
}
</style>
<style>
.roquefort {
  font-size: 13px;
  line-height: 16px;
}

.сaprino {
  font-size: 10px;
  line-height: 12px;
  font-weight: 400;
}
</style>
