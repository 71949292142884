<template>
  <div>
    <div
      :key="count"
      class="flex align-center sulguni mb-7 print pointer"
      @click="linkClick()"
    >
      <r-icon
        class="mr-2 mt-2px"
        icon="arrow-left"
        fill="rocky"
      />
      Назад
    </div>
    <div
      style="font-size: 30px;"
      v-show="isLoadings"
    >
      404!
    </div>
    <div v-show="for404 === false">
      <div class="card_hidden mt-6">
        <div class="width">
          <div>
            <h2>{{ title }}</h2>
          </div>
        </div>
        <div class="mt-1">
          {{ street }}
        </div>
      </div>
      <div class="culture mt-8">
        <div class="culture__cards">
          <div class="card_hidden_down">
            <div class="width">
              <div>
                <h2>{{ title }}</h2>
              </div>
            </div>
            <div class="mt-1">
              {{ street }}
            </div>
            <div class="mt-8">
              <div class="clock">
                Время уборки
              </div>
              <div class="mt-1 flex">
                <r-icon
                  icon="clock"
                  class="mr-1"
                  fill="rocky"
                />
                <p
                  class="card__address mt__2px"
                >
                  {{ details }}
                </p>
                <p
                  class="ml-5 card__address mt__2px"
                >
                  {{ day }}
                </p>
              </div>
            </div>
            <div class="mt-13">
              <div class="clock">
                Дата уборки
              </div>
              <div class="mt-1">
                <tag-carousel
                  :items="cleaningTypes"
                  @select="onChangeType"
                />
              </div>
              <div v-for="cleaning in cleaningListDay">
                <div
                  class="mt-3"
                  v-if="(cleaning.dayNow === 'done' || cleaning.dayNow === 'progress') && tab === 'close'"
                >
                  <p
                    class="card__address"
                  >
                    {{ cleaning.day }}
                  </p>
                </div>
                <div
                  class="mt-3"
                  v-if="cleaning.dayNow === 'wait' && tab === 'active'"
                >
                  <p
                    class="card__address"
                  >
                    {{ cleaning.day }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="['culture__map', { 'culture__map--collapsed': isMapCollapsed }, { 'culture__map-cont': isMapCollapsed }]"
          :key="count"
        >
          <rir-map-leaflet
            :center="cleaningList[0] && cleaningList[0].type === 'yard'
             && cleaningList[0]?.geometry!= null && (cleaningList[0]?.geometry?.length || cleaningList[0]?.geometry[0]?.length) ?
              (cleaningList[0].geometry2 ? cleaningList[0].geometry2[0] : cleaningList[0].geometry[0]) :
              cleaningList[0].lat ? [cleaningList[0].lat, cleaningList[0].lng] : $cityCenter"
            :search-in="false"
          >
            <div
              v-for="cleaning in cleaningList"
              :key="`${cleaning.id}`"
            >
              <l-marker
                :lat-lng="cleaning.lat ? [cleaning.lat, cleaning.lng]
                    : (Array.isArray(cleaning.geometry[0]) ? cleaning.geometry[0] : cleaning.geometry)"
                v-if="cleaning.type === 'yard' && (cleaning?.geometry?.length || cleaning.lat)"
              >
                <l-icon
                  :icon-url="$markerIcon('cleaning_' + getCleaningStatus(cleaning.day, cleaning?.details)).imageHref"
                />
              </l-marker>
              <l-polyline
                v-for="cleaning in cleaningList"
                :lat-lngs="cleaning.geometry || cleaning.geometry2"
                :color="mapStrokeColor(cleaning.day, cleaning.details)"
                v-if="cleaning.type === 'street' && (cleaning?.geometry || cleaning?.geometry2)"
              />
            </div>
            <div
              v-for="cleaning in cleaningList"
              v-if="cleaning && (cleaning?.geometry || cleaning?.geometry2) && cleaning.type === 'territory'"
            >
              <l-polygon
                :lat-lngs="cleaning.geometry || cleaning.geometry2"
                :color="mapStrokeColor(cleaning.day, cleaning.details)"
                :fill-color="mapStrokeColor(cleaning.day, cleaning.details)"
                :weight="1"
              />
            </div>
          </rir-map-leaflet>
          <button
            class="culture__map-collapse-btn feta"
            @click="clickMap()"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
      </div>
    </div>
    <div
      v-show="for404 === false"
      class="card_hidden mt-8"
    >
      <div class="mt-8">
        <div class="clock">
          Время уборки
        </div>
        <div class="mt-1 flex">
          <r-icon
            icon="clock"
            class="mr-1"
            fill="rocky"
          />
          <p
            class="card__address mt__2px"
          >
            {{ details }}
          </p>
          <p
            class="ml-5 card__address mt__2px"
          >
            {{ day }}
          </p>
        </div>
      </div>
      <div class="mt-13">
        <div class="clock">
          Дата уборки
        </div>
        <div class="mt-1">
          <tag-carousel
            :items="cleaningTypes"
            @select="onChangeType"
          />
        </div>
        <div
          v-for="cleaning in cleaningListDay"
          class="tw-100"
        >
          <div
            class="mt-3"
            v-if="(cleaning.dayNow === 'done' || cleaning.dayNow === 'progress') && tab === 'close'"
          >
            <p
              class="card__address"
            >
              {{ cleaning.day }}
            </p>
          </div>
          <div
            class="mt-3"
            v-if="cleaning.dayNow === 'wait' && tab === 'active'"
          >
            <p
              class="card__address"
            >
              {{ cleaning.day }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RirMapLeaflet from '@/components/RirMapLeaflet.vue';
import { LMap, LTileLayer, LMarker, LPolyline, LPolygon, LIcon, LPopup } from 'vue2-leaflet';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
import BalloonCard from '@/components/BalloonCard.vue';
import TagCarousel from '../components/TagCarousel.vue';
import Images from '../components/Image.vue';
import UploadItog from '../components/UploadItog.vue';
import Share from '../components/Share.vue';

export default {
  components: {
    BalloonCard,
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LIcon,
    LPopup,
    LPolygon,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    RirMapLeaflet,
    Share,
    UploadItog,
    Images,
    TagCarousel
  },
  props: {},
  data() {
    return {
      details: '',
      isMapCollapsed: false,
      isShowMap: false,
      map: null,
      coordinates: [this.$cityCenter],
      count: 0,
      title: '',
      street: '',
      day: '',
      dayConvert: '',
      tab: 'active',
      dayNow: '',
      for404: null
    };
  },
  computed: {
    isLoadings() {
      return this.$store.state.isObjectCler;
    },
    cleaningTypes() {
      return [
        { title: 'Ожидается уборка', active: true, id: 'active' },
        { title: 'Уборка завершена', active: false, id: 'close' }
      ];
    },
    isLoading() {
      return this.$store.state.isObjectLoading;
    },
    cleaningListDay() {
      let list = this.$store.state.cleaningListCard;
      if (!list) return [];

      list = list
        .map(el => {
          if (this.title === el.address) {
            return {
              dayNow: this.getCleaningStatus(el.day, el?.details),
              day: ((new Date(el.day).getDate() === new Date().getDate()
                && new Date(el.day).getMonth() === new Date().getMonth()
                && new Date(el.day).getFullYear() === new Date().getFullYear()) ? 'сегодня, ' : '') + new Date(el.day).toLocaleString('ru', {
                month: 'long',
                day: 'numeric'
              })
            };
          }
        });
      list = list.filter(type => {
        if (type?.day) {
          return true;
        }
      });

      return list;
    },
    cleaningList() {
      const _this = this;
      let list = this.$store.state.cleaningListId;

      list.forEach(el => {
        _this.title = el.address;
        _this.street = el.type === 'street' ? 'Улица' : (el.type === 'territory' ? 'Территория' : 'Двор');
        _this.day = el.dayF;
        const today = new Date();
        _this.dayNow = _this.getCleaningStatus(el.day, el?.details);
        _this.details = '09:00 - 17:00';
        if (el?.details?.selectedDate === 'too') {
          _this.details = '00:00 - 06:00';
        } else if (el?.details?.selectedDate === 'three') {
          _this.details = '09:00 - 13:00';
        } else if (el?.details?.selectedDate === 'four') {
          _this.details = '13:00 - 17:00';
        }
        const dateBool = (new Date(el.day).getDate() === today.getDate()
          && new Date(el.day).getMonth() === today.getMonth()
          && new Date(el.day).getFullYear() === today.getFullYear());
        _this.dayConvert = (dateBool ? 'сегодня, ' : '') + new Date(el.day).toLocaleString('ru', {
          month: 'long',
          day: 'numeric'
        });
      });
      if (list.length > 0) {
        this.for404 = false;
      }
      const items = this.$store?.state?.saveInfo;

      list = list
        .map(el => {
          let index = 0;
          let { geometry } = el;
          let geometryIds = el.geometry;
          let geometryIds2 = el.geometry2;
          if(el.extraIds) {
            el.extraIds
              .map(value => {
                if (items.find(els => Number(value) === els.id) && index <= 0) {
                  geometryIds = items.find(els => Number(value) === els.id).geometry;
                  geometryIds2 = items.find(els => Number(value) === els.id).geometry;
                  index++;
                }
                return geometryIds;
              });
            return {
              ...el,
              geometry: geometryIds,
              geometry2: geometryIds2
            };
          } else {
            return {
              ...el,
              geometry: geometryIds
            };
          }
        });
      this.count++;

      return list;
    }
  },
  async created() {
    this.loadStore();
  },
  activated() {
    this.loadStore();
    this.isShowMap = true;
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    async loadStore() {
      await this.$store.dispatch('loadInfoSave');
      await this.$store.dispatch('getCleaningId', this.$route.params.id);
      await this.$store.dispatch('getCleaningCard', { thisMonth: 1 });
    },
    clickMap() {
      this.isMapCollapsed = !this.isMapCollapsed;
      this.count++;
    },
    linkClick() {
      this.$router.push({
        name: 'index',
        params: {}
      });
    },
    timeout() {
      setTimeout(() => this.for404, 2000);
    },
    getCleaningStatus(date, details) {
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 17;
      if (details?.selectedDate) {
        if (details?.selectedDate === 'too') {
          from = 0;
          to = 6;
        } else if (details?.selectedDate === 'three') {
          from = 9;
          to = 13;
        } else if (details?.selectedDate === 'four') {
          from = 13;
          to = 17;
        }
      }
      const hours = new Date().getHours();
      if (toDay === date) {
        if (hours < from) {
          return 'wait';
        } if (hours > to) {
          return 'done';
        }
        return 'progress';
      }

      if (new Date(date) < new Date() && toDay !== date) {
        return 'done';
      }

      return 'wait';
    },
    mapStrokeColor(date, details) {
      let color = '#D06E0B';
      const toDay = new Date().toJSON().substring(0, 10); // 'yyyy-mm-dd'
      let from = 9;
      let to = 17;
      if (details?.selectedDate) {
        if (details?.selectedDate === 'too') {
          from = 0;
          to = 6;
        } else if (details?.selectedDate === 'three') {
          from = 9;
          to = 13;
        } else if (details?.selectedDate === 'four') {
          from = 13;
          to = 17;
        }
      }
      const hours = new Date().getHours();
      if (toDay === date) {
        if (hours < from) {
          color = '#D06E0B';
        } else if (hours > to) {
          color = '#57A003';
        } else {
          color = '#3D75E4';
        }
      }

      if (new Date(date) < new Date() && toDay !== date) {
        color = '#57A003';
      }

      return color;
    },
    onChangeType(type) {
      this.tab = type.id;
    }
  }
};
</script>

<style lang="scss" scoped>
.card_hidden {
  display: none;
}

.card_hidden_down {
  display: table;
}

.opacity {
  color: #04153E;
  opacity: 0.48;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.width {
  display: table;
  width: 100%;
}

.culture {
  display: flex;
  justify-content: space-between;

  &__cards {
    width: 41.7%;
  }

  &__map-cont {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 448px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }
  &__map {
    position: relative;
    display: flex;
    height: 448px;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    z-index: 100000;
    display: none;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .card_hidden {
    display: table;
  }
  .card_hidden_down {
    display: none;
  }
  .culture {
    max-width: 100%;
    flex-direction: column-reverse;

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      height: unset;
      padding: 0;
      height: 448px;
      margin-left: unset;
      width: 100%;
    }
    &__map--collapsed {
      height: 112px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (max-width: 599px) {
  .card_hidden_down {
    display: none;
  }
  .card_hidden {
    display: table;
  }
}
.clock {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #04153E;
  opacity: 0.48;
}

.tw-100 {
  display: table;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.mt__2px {
  margin-top: -2px;
}
</style>
