import Vue from 'vue';
import VueRouter from 'vue-router';
import ListSavedObject from '../components/views/saved/list/ListSavedObject.vue';
import EditSavedObject from '../components/views/saved/edit/EditSavedObject.vue';
import NewSavedObject from '../components/views/saved/new/NewSavedObject.vue';
import Admin from '../views/Admin.vue';
import ObjectList from '../views/ObjectList.vue';
import EditCleaning from '../components/views/cleaning/EditCleaning.vue';
import NewEvent from '../components/views/event/new/NewEvent.vue';
import EditEvent from '../components/views/event/edit/EditEvent.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin',
    component: Admin,
    children: [
      {
        name: 'index',
        path: '',
        component: ObjectList
      },
      {
        name: 'save',
        path: 'save',
        component: ObjectList
      },
      {
        name: 'edit',
        path: 'edit/:id',
        component: EditCleaning
      },
      {
        name: 'new',
        path: 'new',
        component: EditCleaning
      },
      {
        name: 'new-clear',
        path: 'new-clear',
        component: NewEvent
      },
      {
        name: 'save-objects',
        path: 'save-objects',
        component: ListSavedObject
      },
      {
        name: 'edit-objects',
        path: 'save-objects/edit/:id',
        component: EditSavedObject
      },
      {
        name: 'new-objects',
        path: 'save-objects/new/:type',
        component: NewSavedObject
      },
      {
        name: 'copy',
        path: 'copy',
        component: EditCleaning
      },
      {
        name: 'clear-edit',
        path: 'clear-edit/:id/:bul',
        component: EditEvent
      }
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
});

export default router;
