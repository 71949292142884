<template>
  <div>
    <div class="container-800">
      <p class="camembert mb-8">
        Жители
      </p>
      <dropdown-search
        label="Поиск адресу"
        @changed="onChanged"
        :resident-addressees="residentAddressees"
      />
      <div class="button_container">
        <div class="container-800">
          <r-button
            @click="submit"
            title="Сохранить"
            width="wide"
          />
          <p class="caprino opacity-48 mt-3">
            Выбрано адресов: {{ choicest.length }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownSearch from './DropdownSearch';

export default {
  name: 'ModalResidentAddressees',
  components: { DropdownSearch },
  props: {
    saveFunction: {
      type: Function,
      required: true
    },
    residentAddressees: {
      type: Array
    }
  },
  data() {
    return {
      choicest: [],
      searchText: ''
    };
  },
  mounted() {
    this.choicest = this.$props.residentAddressees;
  },
  methods: {
    onChanged(arr) {
      this.choicest = arr;
    },
    submit() {
      this.saveFunction(this.choicest);
      this.$rir.modal.close();
    }
  }
};
</script>

<style scoped lang="scss">

.container-800 {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
.button_container {
  padding: 24px 0px 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
  display: flex;
  justify-content: center;
  text-align: center;
}
</style>
