<template>
  <div ref="content">
    <loader v-if="isLoading" />
    <div
      style="font-size: 30px;"
      v-else-if="for404"
    >
      404!
    </div>
    <div
      :class="{'button_fly': flyButton}"
      v-else-if="timeout"
    >
      <div
        class="flex align-center sulguni mb-7 rocky--text pointer"
        @click="linkClick()"
      >
        <r-icon
          class="mr-2 mt-2px"
          icon="arrow-left"
          fill="rocky"
          size="16"
        />
        Назад
      </div>
      <r-bulb
        :is-block="true"
        :is-shape="false"
        color="fargo"
        :title="!isSave && isEditPage ? 'Не сохранено' : ''"
        position="eluno"
        size="marina"
      >
        <template #content>
          <h1
            class="ricotta"
          >
            {{ isEditPage ? 'Редактирование уборки' : 'Новая уборка' }}
          </h1>
        </template>
      </r-bulb>
      <div>
        <div class="grid-cont mt-8">
          <div class="edit__form">
            <r-input
              class=""
              :error="!address"
              label="Адрес или название уборки"
              v-model="address"
              @input="setClusterByAddress()"
            />
            <r-textarea
              class="mt-6"
              label="Описание"
              :rows="5"
              v-model="description"
              @input="isChange('description')"
            />
            <h3 class="mt-7 mb-4">
              Тип территории
            </h3>
            <r-radio-button
              class="mr-6 mb-3"
              v-model="selectedCategory"
              val="street"
              title="Улица"
              @input="onChangeType(), isChange('selectedCategory')"
            />
            <r-radio-button
              class="mr-6 mb-3"
              v-model="selectedCategory"
              val="yard"
              title="Двор"
              @input="onChangeType(), isChange('selectedCategory')"
            />
            <r-radio-button
              class="mr-6"
              v-model="selectedCategory"
              val="territory"
              title="Территория"
              @input="onChangeType(), isChange('selectedCategory')"
            />
            <h3 class="mt-7 mb-4">
              Расположение
            </h3>
            <r-radio-button
              class="mr-6 mb-3"
              :value="selectedDraw"
              title="Указать вручную"
              @input="onChangeType(), isChange('selectedCategory'), selectedDraw = true"
            />
            <r-radio-button
              class="mr-6"
              :value="!selectedDraw"
              title="Выбрать существующие"
              @input="onChangeType(), isChange('selectedCategory'), selectedDraw = false"
            />
            <div
              class="mt-4 flex align-start"
              v-if="!selectedDraw"
            >
              <div class="flex-1 mr-2">
                <r-select
                  :key="'select_' + countMap"
                  label="Объект"
                  :items="itemsList"
                  v-model="items"
                  is-search
                  return-object
                />
                <div :key="countMap">
                  <contact-items
                    class="mt-4"
                    :icon="'attach'"
                    text-value="title"
                    sub-value="title"
                    @input="removeItem"
                    v-model="selected"
                  />
                </div>
              </div>
              <r-button
                @click="addItem"
                icon="add"
                :title="''"
              />
            </div>
          </div>
          <div class="edit__map">
            <r-date-picker
              label="Выберите даты уборки"
              v-model="calendarDate"
              @input="onClickDate"
              :is-input="false"
              :selected="cleaningDates"
              :click-close="isEditPage"
              :error="(!isEditPage && !cleaningDates.length) || (isEditPage && !calendarDate)"
            />
            <r-select
              class="flex-1 mt-4"
              :items="objectDate"
              label="Время уборки"
              v-model="selectedDate"
            />
            <div class="h-250">
              <div
                class="selected-dates"
                v-if="!!formatDates.length"
              >
                <div
                  class="selected-dates__badge"
                  v-for="(date, index) in formatDates"
                  :key="index"
                >
                  {{ date }}

                  <button
                    class="selected-dates__clear"
                    @click="clearDate(index)"
                  >
                    <r-icon
                      icon="close"
                      fill="amelie"
                      size="16"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="text-footer mb-4">
              Добавьте контрольные точки маршрута на карту
            </div>
            <div :key="countMap">
              <rir-map-leaflet
              @clickMap="onMapClick"
              @mapInit="onMapInit"
              @clickDb="onMapDbClick"
              @clickR="onMapRightClick"
              @mapCoord="mapCoord"
              style="height: 400px;"
              collapse-btn
              :street="selectedCategory === 'yard' ? true : false"
              :center="$cityCenter"
            >
              <div
                v-if="coordinates && selectedCategory === 'yard' && selectedDraw"
                :key="countMarker"
              >
                <l-marker
                  v-for="(marker, index) in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                  @click.right="markerRight($event, index)"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
              </div>
              <div
                v-for="cleaning in selected"
              >
                <l-marker
                  :lat-lng="cleaning.lat ? [cleaning.lat, cleaning.lng] : cleaning.geometry"
                  v-if="cleaning && cleaning.type === 'yard' && selectedCategory === 'yard'"
                >
                  <l-icon
                    :icon-url="$markerIconTwo.imageHref"
                  />
                </l-marker>
                <l-polyline
                  v-else-if="cleaning && cleaning.geometry && cleaning.type === 'street' && selectedCategory === 'street'"
                  :lat-lngs="cleaning.geometry"
                  :color="'#4480F3'"
                />
                <l-polygon
                  v-if="cleaning && cleaning.geometry && cleaning.type === 'territory' && selectedCategory === 'territory'"
                  :lat-lngs="cleaning.geometry"
                  :color="'#4480F3'"
                  :weight="1"
                />
              </div>
            </rir-map-leaflet>
            </div>
          </div>
        </div>
        <div
          class="flex align-center custom mt-10 mb-60px pointer fargo-hover mb-20"
          @click="deleteItem"
          v-if="isEditPage"
        >
          <r-icon
            icon="delete"
            fill="fargo"
            size="16"
          />
          <div class="fargo--text ml-2">
            Удалить уборку
          </div>
        </div>
        <div class="button_container mt-10 work-form__btn-cont">
          <r-button
            width="wide"
            :title="isEditPage ? 'Сохранить' : 'Добавить'"
            class="flex-1"
            @click="submit"
            :disabled="isSave"
          />
        </div>
      </div>
      <r-modal
        ref="modal"
        close-icon
        fullscreen
      />
    </div>
  </div>
</template>

<script>
import contactItems from '@/components/components/ContactItems.vue';
import Loader from '@/components/ states/Loader.vue';
import RirMapLeaflet from '@/components/maps/RirMapLeaflet.vue';
import { LMarker, LPolyline, LPolygon, LIcon } from 'vue2-leaflet';
import L from 'leaflet';
import { EditablePolyline } from 'vue2-leaflet-editable';
import ErrorModal from '@/components/modals/ErrorModal.vue';
import SavedModal from '../../modals/SavedModal.vue';
import DeleteModal from '../../modals/delete/DeleteModal.vue';
import PlaceApi from '../../../api/PlaceApi';

export default {
  components: {
    EditablePolyline,
    LMarker,
    LPolyline,
    LIcon,
    LPolygon,
    contactItems,
    Loader,
    RirMapLeaflet
  },
  data() {
    return {
      mark: true,
      polyline: null,
      markers: [],
      points: '',
      selectedDraw: true,
      isDraw: true,
      count: 0,
      list: [],
      items: null,
      selectedDate: 'one',
      objectDate: [
        { id: 'too', title: '00:00 - 06:00' },
        { id: 'three', title: '09:00 - 13:00' },
        { id: 'four', title: '13:00 - 17:00' },
        { id: 'one', title: '09:00 - 17:00' },
      ],
      selected: [],
      objectTypes: [
        { id: 'street', title: 'Улица' },
        { id: 'yard', title: 'Двор' },
        { id: 'territory', title: 'Территория' }
      ],
      pol: [],
      streetGeometry: null,
      calendarDate: null,
      cleaningDates: [],
      mapInstance: null,
      description: null,
      selectedCategory: 'street',
      address: null,

      isLoading: true,
      openWarn: false,
      coordinates: [],
      isShowMap: false,
      isTypeChanged: false,
      flyButton: false,
      isSave: true,
      initialData: {},
      countMap: 0,
      mapIs: true,
      for404: null,
      extraIds: null,
      latMarker: null,
      lngMarker: null,
      countMarker: 0,
      clean: {}
    };
  },

  computed: {
    itemsList() {
      let items = this.$store?.state?.saveInfo;
      this.items = null;
      if (items !== null) {
        items = items.map(el => ({ ...el, id: el.id, title: el.address, value: el.address }));
      } else {
        items = [];
      }
      if (this.selectedCategory === 'yard') {
        items = items.filter(el => el?.type === 'yard');
      } else if(this.selectedCategory === 'territory') {
        items = items.filter(el => el?.type === 'territory');
      } else {
        items = items.filter(el => el?.type === 'street');
      }

      return items;
    },
    isLoadings() {
      return this.$store.state.isObjectLoading;
    },
    isCanSave() {
      return this.address && (this.cleaningDates.length || this.calendarDate);
    },
    isEditPage() {
      return this.$route.name === 'edit' && !this.isCopy;
    },
    isCopy() {
      return this.$route.name === 'copy';
    },
    id() {
      return this.isEditPage ? this.$route.params.id : null;
    },
    formatDates() {
      return this.cleaningDates.map(date => {
        const nDate = new Date(date);
        return nDate.toLocaleDateString('RU-ru');
      });
    }
  },
  watch: {
    markers: {
      immediate: true,
      deep: true,
      handler() {
        if (this.mark) {
         // this.setAddressByCluster();
        }
      }
    }
  },
  async mounted() {
    const recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://rawgit.com/Leaflet/Leaflet.Editable/master/src/Leaflet.Editable.js'
    );
    document.head.appendChild(recaptchaScript);
  },
  updated() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },

  async activated() {
    const toDay = new Date();
    toDay.setDate(toDay.getDate());

    this.$store.dispatch('getCleaningEdit', { id: this.$route.params.id }).then(value => {
      this.resetData();
      this.clean = this.cleanItems();
      (this.isEditPage || this.isCopy) && this.setData();
      this.isShowMap = true;
      this.selectedDraw = this.clean?.details?.selectedDraw !== undefined ? this.clean?.details?.selectedDraw : true;
      this.$store.dispatch('loadInfoSave').then(() => {
        const items = this.$store?.state?.saveInfo;
        this.funcSelect(items);
      });
    });
  },

  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    cleanItems() {
      return this.$store.getters.getCleanById(this.$route.params.id);
    },
    funcSelect(items) {
      this.selected = [];
      let index = 0;
      this.selectedDraw = this.clean?.details?.selectedDraw !== undefined ? this.clean?.details?.selectedDraw : true;
      if(!this.clean?.extraIds) {
        this.clean.extraIds = [this.clean?.addressId];
      }
      if (this.clean?.extraIds && !this.selectedDraw) {
          this.selected = items.map(el => {
            if (this.clean?.extraIds && this.clean.extraIds.includes(el.id) && index <= 0) {
              this.isDraw = false;
              this.pol.forEach(el => {
                el.disableEdit();
              });
              index++;
              return {
                ...el,
                geometry: (el?.geometry
                  ? (typeof el.geometry[0] !== 'string' && typeof el.geometry[0][0] !== 'string'
                      ? el.geometry
                      : [el.geometry]
                  )
                  : []),
                id: el.id,
                title: el.address,
                value: el.address
              };
            }
          });
        this.selected = this.selected.filter(el => (el !== undefined));
      }
      if (this.selected.length === 0) {
        this.selected = [];
      }
      this.isLoading = false;
    },
    addItem() {
      const item = this.itemsList.filter(el => (el.id === this.items.id));
      const selected = this.selected.filter(el => (el.id === this.items.id));
      if (selected.length === 0) {
        if (!this.isEditPage) {
          this.selected.push(item[0]);
        } else if (this.selected.length <= 0) {
          this.selected.push(item[0]);
        }

        this.isDraw = false;
      }
    },
    removeItem(arr) {
      this.selected = arr;
      this.isDraw = false;
    },
    linkClick() {
      const r = this.$router.resolve({
        name: 'index',
        params: {}
      });
      history.pushState({}, '', r.href);
    },
    submit() {
      if (this.isEditPage) {
        this.OpenSaveModal();
      } else {
        this.onSave();
      }
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SavedModal, {
        saveFunc: this.onSave,
        title: this.address
      });
    },
    async deleteItem() {
      await this.$refs.modal.openModal(DeleteModal, {
        id: Number(this.$route.params.id),
        actionType: 'deleteClean',
        address: this.address
      });
    },
    isChange(field) {
      if (field === 'selectedCategory') {
        this.selected = [];
        this.countMap++;
      }
      if (this.initialData[field] === this[field]) {
        this.isSave = true;
      } else {
        this.isSave = false;
      }
    },
    clearDate(index) {
      this.cleaningDates.splice(index, 1);
    },
    onChangeType() {
      this.isTypeChanged = true;
      this.coordinates = [];
      this.streetGeometry = null;
      this.selected = [];
      this.pol = [];
      this.markers = [];
      const newMarker = {
        position: this.$cityCenter,
        draggable: true,
        visible: true
      };
      if (this.markers.length === 0) {
        this.markers.push(newMarker);
      }

      this.countMap++;
    },
    mapCoord(coord) {
      this.latMarker = coord.lat;
      this.lngMarker = coord.lng;
    },
    onMapInit(e) {
      this.mapInstance = e;
      if ((this.selectedCategory === 'street' || this.selectedCategory === 'territory')
        && ((this.clean?.extraIds?.length === 0 || this.clean?.extraIds === null) && this.selectedDraw)) {
        if ((!this.coordinates || this.coordinates.length === 0) && this.selectedCategory === 'street') {
          this.pol[0] = this.selectedCategory === 'territory'
            ? this.mapInstance.mapObject.editTools.startPolygon()
            : this.mapInstance.mapObject.editTools.startPolyline();
          this.pol[0].on('click', e => {
            this.pol.forEach(el => {
              el.disableEdit();
            });
            e.target.enableEdit();
            e.target.editor.continueForward();
          });
          this.pol[0].on('contextmenu', e => {
            this.pol.splice(0, 1);
            e.target.remove(this.mapInstance);
          });
        }
      }
      if (this.selectedCategory !== 'yard' && this.mapInstance.mapObject
        && (this.clean?.extraIds?.length === 0 || this.clean?.extraIds === null || this.clean.details?.selectedDraw)) {
        if(this.selected.length > 0 && !this.selectedDraw) {
          this.coordinates = this.selectedCategory === 'territory' ? this.selected.geometry : this.selected.geometry;
        }
        if (this.coordinates === undefined) {
          this.coordinates = [this.$cityCenter];
        }

        if (this.coordinates && this.coordinates.length) {
          let index = 0;
          this.coordinates.map(items => {
            if(index <= 0) {
              this.pol.push(this.selectedCategory === 'territory' ? L.polygon([items]).addTo(this.mapInstance.mapObject) : L.polyline(items).addTo(this.mapInstance.mapObject));
              index++;
            }

            this.pol[this.pol.length - 1].on('click', e => {
              this.pol.forEach(el => {
                el.disableEdit();
              });
              e.target.enableEdit();
              e.target.editor.continueForward();
            });
            this.pol[this.pol.length - 1].on('contextmenu', e => {
              this.pol.splice(this.pol.length - 1, 1);
              this.pol.forEach(el => {
                el.disableEdit();
              });
              e.target.remove(this.mapInstance);
            });
            this.pol.forEach(el => {
              el.disableEdit();
            });
          });
        }
      }
    },
    markerRight(e, index) {
      if (this.selectedDraw) {
        e.target.remove(this.mapInstance);
        this.markers.splice(index, 1);
      }

      this.countMarker++;
    },
    onClickDate(date) {
      if (!date || this.isEditPage) return;

      const index = this.cleaningDates.findIndex(el => el === date);

      if (index !== -1) {
        this.cleaningDates.splice(index, 1);
      } else {
        this.cleaningDates.push(date);
      }

      this.isChange('calendarDate');
    },

    markersWasAdd(e) {
      if (!e[0].clusterName && this.isDraw) {
        this.polyLine = e[0];
        e[0].editor.startDrawing();
      }

      if (this.selectedCategory === 'yard') this.setAddressByCluster(e);
    },

    async setAddressByCluster() {
      if (this.selectedCategory !== 'yard') return;

      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ lat: this.markers[0].position.lat, lng: this.markers[0].position.lng });

      const { address } = geo;
      this.mark = true;
      if (address) this.address = address;
    },

    async setClusterByAddress() {
      this.isSave = false;
      if (this.selectedCategory !== 'yard') return;

      const api = new PlaceApi();
      const geo = await api.getGeoSearch({ address: this.address });

      const { lat, lng } = geo;
      this.mark = false;
      if(!geo?.error || !geo?.data?.error) {
        this.markers[0].position = {lat, lng};
      }
      this.mark = true;
      this.isChange('coordinates');
    },

    onMapClick() {
      if (this.selectedCategory === 'street' || this.selectedCategory === 'territory') {
        this.isSave = false;
      }
    },
    onMapDbClick() {
      if (this.selectedDraw) {
        this.pol.forEach(el => {
          el.disableEdit();
        });
      }
    },
    onMapRightClick() {
      if (this.selectedCategory === 'yard') {
        if (this.selectedDraw) {
          const newMarker = {
            position: [this.latMarker, this.lngMarker],
            draggable: true,
            visible: true
          };
          if(this.markers.length <= 0) {
            this.markers.push(newMarker);
          }
          this.countMarker++;
        }
      } else if (this.selectedDraw) {
        this.pol.forEach(el => {
          el.disableEdit();
        });
        if(this.pol.length <= 0) {
          this.pol.push(this.selectedCategory === 'territory' ? this.mapInstance.mapObject.editTools.startPolygon() : this.mapInstance.mapObject.editTools.startPolyline());
        }
        this.pol[this.pol.length - 1].on('click', e => {
          this.pol.forEach(el => {
            el.disableEdit();
          });
          e.target.enableEdit();
          e.target.editor.continueForward();
        });

        this.pol[this.pol.length - 1].on('contextmenu', e => {
          this.pol.splice(this.pol.length - 1, 1);
          this.pol.forEach(el => {
            el.disableEdit();
          });
          e.target.remove(this.mapInstance);
        });
      }
    },
    timeout() {
      setTimeout(() => this.for404, 1000);
    },
    async onSave() {
      if (!this.isCanSave) return;
      this.isLoading = true;
      let lat = null;
      let lng = null;

      const format = date => new Date(date).toLocaleString('ru-Ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      });

      const formatedDates = this.cleaningDates.map(format);
      let items = null;
      if (!this.selectedDraw) {
        items = this.selected.map(el => el.id);
      }
      let pol = [];
      if (this.selected.length > 0) {
        pol = [];
        this.streetGeometry = [];
        if (this.selected) {
          this.selected.forEach(items => {
            if (items.geometry.length > 0) {
              pol.push(items.geometry);
            } else {
              if (items.lat && items.lng) {
                pol.push([items.lat, items.lng]);
                lat = items.lat;
                lng = items.lng;
              }
            }
          });
        }
      }
      if (this.pol) {
        this.streetGeometry = [];
        this.pol.forEach(items => {
          let info = items._latlngs;
          if(this.selectedCategory === 'territory') {
            let territory = [];
            for (let i = 0; i < items._latlngs[0].length; i++) {
              const item = JSON.parse(
                String(items._latlngs[0][i])
                  .split('LatLng(')
                  .join('[')
                  .split(')')
                  .join(']')
              );
              territory.push([item[0], item[1]]);
              lat = item[0];
              lng = item[1];
            }

            this.streetGeometry.push(territory);
          } else {
            this.streetGeometry = [];
            if (!this.selectedDraw) {
              info = items._latlngs[0]
            }
              for (let i = 0; i < info.length; i++) {
                const item = JSON.parse(
                  String(info[i])
                    .split('LatLng(')
                    .join('[')
                    .split(')')
                    .join(']')
                );
                this.streetGeometry.push([item[0], item[1]]);
                lat = item[0];
                lng = item[1];
              }
            }
            if (this.streetGeometry.length > 0) {
              pol.push(this.streetGeometry);
            }
        });
      }
      let from = 9;
      let to = 17;
      if (this.selectedDate) {
        if (this.selectedDate === 'too') {
          from = 0;
          to = 6;
        } else if (this.selectedDate === 'three') {
          from = 9;
          to = 13;
        } else if (this.selectedDate === 'four') {
          from = 13;
          to = 17;
        }
      }

      const markers = [];
      this.markers.forEach(items => {
        if (items.position?.lat) {
          markers.push([items.position?.lat, items.position?.lng]);
          lat = items.position?.lat;
          lng = items.position?.lng;
        } else {
          markers.push(items.position);
          lat = items.position[0];
          lng = items.position[1];
        }
      });
      if(items) {
        items = items.map(function(el) {
          return String(el)
        })
      }
      const data = {
        address: this.address,
        description: this.description,
        type: this.selectedCategory,
        geometry: this.selectedCategory === 'territory' ?
          (this.selectedDraw ? this.streetGeometry : pol[0]) :
          (this.selectedCategory === 'street' ? (pol.length > 0 ? pol
              : (this.selected[0]?.geometry ? this.selected[0]?.geometry : ''))
            : (pol.length === 0 || this.selectedDraw
                ? markers
                : pol
            )),
        geometry2: this.selectedCategory === 'territory' ?
          (this.selectedDraw ? this.streetGeometry : pol[0]) :
          (this.selectedCategory === 'street' ? (pol.length > 0 ? pol
          : (this.selected[0]?.geometry ? this.selected[0]?.geometry : ''))
          : (pol.length === 0 || this.selectedDraw
            ? markers
            : pol
          )),
        lat: lat,
        lng: lng,
        extraIds: items?.length > 0 ? items : [],
        details: {
          from,
          to,
          selectedDate: this.selectedDate,
          selectedDraw: this.selectedDraw
        }
      };
      data.noAddressid = true;
      if (this.isEditPage) {
        data.day = this.calendarDate;
      } else if (this.isCopy) {
        data.date = this.calendarDate;
      } else {
        data.date = formatedDates.join();
      }

      this.$store.dispatch('setCleaning', { id: this.id, noAddressid: true, data }).then(res => {
        this.isLoading = false;
        if (res?.error) {
        } else {
          let r = this.$router.resolve({
            name: 'index',
          });

          window.location.assign(r.href)
        }
      });
    },

    setData() {
      if (this.clean === undefined) {
        this.for404 = true;
      } else {
        this.for404 = false;
      }
      this.address = this.clean.address ? this.clean.address : null;
      this.calendarDate = this.isEditPage ? this.clean.day : null;
      this.streetGeometry = this.clean.type === 'street' ? this.clean.geometry2 : null;
      this.selectedCategory = this.clean.type;
      this.description = this.clean.description;
      this.coordinates = (this.clean?.geometry
          ? (typeof this.clean.geometry[0] === 'object' && typeof this.clean.geometry[0][0] === 'object'
            ? (typeof this.clean.geometry[0][0][0] === 'object' ? this.clean?.geometry[0] : this.clean?.geometry) : [this.clean?.geometry])
          : []);
      this.coordinates2 = this.clean.geometry2 ? this.clean?.geometry2 : this.clean?.geometry;
      this.initialData = JSON.parse(JSON.stringify(this.clean));
      this.selectedDate = this.clean?.details?.selectedDate ? this.clean.details.selectedDate : 'one';

      if (this.clean.type === 'yard' && this.clean?.details?.selectedDraw) {
        this.coordinates.forEach(item => {
          const addMarker = {
            position: typeof item[0] === 'object' ? item[0] : item,
            draggable: true,
            visible: true
          };
          this.markers.push(addMarker);
        });
      } else {
        this.markers = [];
        const newMarker = {
          position:
            this.coordinates.length > 0 && typeof this.coordinates[0] === 'Array' ?
              (typeof this.coordinates[0][0] === 'Array' ?
            (typeof this.coordinates[0][0][0] === 'Array' ? this.coordinates[0][0][0] : this.coordinates[0][0])
                : this.coordinates[0])
              : (this.clean?.lat && this.clean.type === 'yard' ? [this.clean?.lat, this.clean?.lng] : this.$cityCenter),
          draggable: true,
          visible: true
        };
        this.markers.push(newMarker);
      }
      this.countMap++;
    },

    resetData() {
      this.isTypeChanged = false;
      this.isLoading = false;
      this.streetGeometry = null;
      this.polyLine = null;
      this.calendarDate = null;
      this.cleaningDates = [];
      this.mapInstance = null;
      this.description = null;
      this.selectedCategory = 'street';
      this.address = null;
      this.coordinates = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.button_container {
  margin-top: 30px;
  padding-top: 32px;
}

.RButton{
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.dates {
  display: grid;
  grid-gap: 10px 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.edit {
  &__map {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }
}

.selected-dates {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__container {
    font-size: 12px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    padding: 4px 8px;
    border-radius: 50px;
  }

  &__badge {
    padding: 4px 8px;
    border-radius: 30px;
    background-color: var(--rir-alien);
    color: var(--rir-amelie);
    font-size: 14px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;

    &:hover .selected-dates__clear {
      opacity: 1;
      visibility: visible;
    }
  }

  &__clear {
    position: absolute;
    top: 0;
    right: 0;
    align-self: stretch;
    height: 100%;
    width: 40%;
    background-image: linear-gradient(90deg, transparent, var(--rir-alien) 70%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
    cursor: pointer;
  }
}

::v-deep .rir-map__legend {
  display: none;
}

.work-form__btn-cont {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 74.7%;
  background-color: #fff;
  z-index: 2;
  padding: 26px 40px 26px 32px;
  box-shadow: 0 9px 28px rgb(17 48 121 / 18%);
}

.text-footer {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.rir-date-picker__content {
  top: 143px !important;
  margin-top: 45px;
  right: 40px !important;
}

.h-250 {
  height: 250px;
  display: table;
}
</style>
<style>
input:-webkit-autofill, input:focus, textarea:-webkit-autofill, textarea:focus, select:-webkit-autofill, select:focus {
  background-color:transparent !important;
}

.fargo {
  background-color: unset !important;
}
</style>
